<template>   
 <MobileHeader
    v-show="!showNotiPreference"
    :mobileHeaderTitle="'NOTIFICATIONS'"
    :tabs="$route.name"
    :isShowNotiPreferenceCog="true"
    @toggleSettingEvent="toggleSetting"
  ></MobileHeader>
    <div v-show="isMobile && showNotiPreference" class="mobile-header__wrapper">
        <div class="mobile-account__header-back" @click="toggleSetting()"> <img src="../../../static/images/icons/icon_arrow_left_white_m.png"></div>
        <div class="bold">{{$t('PREFERENCES')}}</div>
        <div class="mobile-account__header-back"></div>
    </div>

    <div class="container has-space" :class="{'has-header': isMobile && !showNotiPreference}" ref='scrollComponent'>
        <h1 class="page-title" v-if="!isMobile">{{ $t('NOTIFICATIONS') }}</h1>
        <div class="noti-wrapper">
            <NotificationNew :showNewNotification=showNewNotification @emitNewNotificationEvent="listenNewNotificationEvent"/>
            <div class="noti-subtitle-wrapper" v-show="!isMobile">
                <span class="noti-subtitle"
                ><span v-show="showNotiPreference">{{ $t("PREFERENCES") }}</span></span
                >
                <Cog @click="toggleSetting" :isActive="showNotiPreference"></Cog>
            </div>
            <Preference ref="prefrenceRef"></Preference>
            <div v-show="!showNotiPreference" class="noti-container scrollbar-default relative" @scroll="handleScrollContent($event)" ref="notificationRef">
                <NotificationList  ref="notificationListRef" @emitNotificationListEvent="listenNotificationListEvent" pageFrom="notificationPage"/>
            </div>
        </div>
    </div>
</template>
<script>
import NotificationList from "@/views/notifications/components/List.vue"
import MobileHeader from "@/components/mobile/Header.vue";
import NotificationNew from "@/views/notifications/components/New.vue"
import Cog from "@/components/ui/Cog.vue";
import Preference from "@/views/notifications/components/Preference.vue"
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            isScrolling: false,
            showNewNotification: false,
            returnAPIData: {
                isFinishLoad: false,
                hasNext: true
            },
            showNotiPreference: false,
        }
    },
    components: {
        NotificationList,
        MobileHeader,
        NotificationNew,
        Cog,
        Preference,
    },
    mounted() {
        this.commonVerifyLogin();
        this.init();
        if (this.isMobile) {
             window.addEventListener('scroll', this.handleScrollMobile);
        }
    },
    beforeUnmount(){
        window.removeEventListener('scroll', this.handleScrollMobile);
    },
     computed: {
        ...mapGetters([
            "isMobile",
            "hasNewNotification",
            "isLogin",
            "currentLocale"
        ]),
    },
     watch: {
        hasNewNotification: {
            deep: true,
            handler(newVal, oldVal) {
                this.showNewNotification = newVal;
                if (!this.showNewNotification) {
                    this.listenNewNotificationEvent();
                }
            },
        },
        isLogin: {
            deep: true,
            handler(newVal, oldVal) {
                if (!newVal) {
                  this.$router.push({ name: 'home'})
                }
            },
        },
    },
    methods: {
        ...mapActions([
            "getNotificationList",
            "toggleNewNotification",
            "commonVerifyLogin",
        ]),

        init() {

            if (this.isLogin) {
                this.$refs.notificationListRef.init();
            } else {
                location.href = `/${this.currentLocale}`
            }      
        },

        toggleSetting() {
            this.showNotiPreference = !this.showNotiPreference;
            this.$refs.prefrenceRef.toggleSetting();
        }, 
        listenNewNotificationEvent() {
            if (this.isMobile) {
                window.scrollTo(0,0)              
            } else {
                this.$refs.scrollComponent.scrollTo(0,0)
            }
            this.isScrolling = true;

            //delay to show the loading effect
            setTimeout(() => {          
                this.toggleNewNotification(false);
                this.init(); 
                this.isScrolling = false;
            },100);            
        },
        listenNotificationListEvent(params) {          
            this.returnAPIData = params;
        },

        handleScrollContent(event) {  
                      
            if (!this.isScrolling) {
                if (event.currentTarget.scrollTop === 0 && this.hasNewNotification) {
                    this.isScrolling = false;
                    //this.toggleNewNotification(false);
                    //this.init(); 
                } else {     
                           
                    let element = event.currentTarget
                   
                    if (element.scrollTop + element.offsetHeight + 2 >= element.scrollHeight && this.returnAPIData.hasNext) {   
                                               
                        this.$refs.notificationListRef.loadMorePosts()
                    }
                }
            }
        },

        handleScrollMobile() {
            let element = this.$refs.scrollComponent;

            if (element.getBoundingClientRect().bottom < window.innerHeight && this.returnAPIData.isFinishLoad && this.returnAPIData.hasNext) {
                this.returnAPIData.isFinishLoad = false;
                this.$refs.notificationListRef.loadMorePosts()
            }
        },
    },
}
</script>

<style scoped>
.noti-wrapper{
    width: 37rem;
    margin: 0 auto;
    background-color: var(--color-theme);
    border-radius: .5rem;
    position: relative;
    margin-bottom: 2rem;
    box-sizing: border-box;
}
.noti-container{
    min-height: 37rem;
    max-height: 37rem;
    padding: 1rem 0;
    overflow-y: auto
}
.noti-subtitle-wrapper {
  padding: 0.813rem 1.25rem 0 1rem;
  display: flex;
  justify-content: space-between;
}
.noti-subtitle {
  font-size: 0.875rem;
  font-weight: 700;
}

@media (max-width: 768px) {
    .mobile-header__wrapper{
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .noti-container{
        width: auto;
        background-color: transparent;
        padding: 0;
        padding-top: 1rem;
        margin-bottom: 1.5rem;
        max-height: none;
        overflow-y: hidden
    }
    .noti-wrapper{
        width: 100%;
        background-color:transparent
    }
}
</style>